/*
 * @Description:
 * @Author: wyhuo2
 * @Date: 2020-08-17 11:08:41
 * @LastEditTime: 2021-04-02 16:57:05
 * @LastEditors: wyhuo2
 */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/Download.vue')
  }
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
export default router;
