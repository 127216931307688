/*
 * @Description:
 * @Author: wyhuo2
 * @Date: 2020-08-17 11:08:41
 * @LastEditTime: 2021-03-15 16:19:54
 * @LastEditors: wyhuo2
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/style/index.scss';
import '@/style/element-ui.scss';
import '@/style/element-variables.scss';
import Element from 'element-ui';
import VueFullPage from 'vue-fullpage.js';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import I18NextVue from 'i18next-vue';
import i18n from '@/i18n';

Vue.use(VueAwesomeSwiper);
Vue.use(VueFullPage);
Vue.use(Element, { size: 'small' });
Vue.use(I18NextVue, { i18next: i18n });
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
