import i18next, { Module } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Resource } from "i18next/typescript/options";

const DEFAULT_LANG = "zh";
const detection = {
  order: ["querystring", "localStorage"],
  lookupQuerystring: "i18nextLng",
  lookupLocalStorage: "i18nextLng",
  caches: ["localStorage"],
};

const initI18n = (useList: Module[] = [], resources: Resource) => {
  useList.forEach((usePackage) => i18next.use(usePackage));
  i18next
    .use(LanguageDetector)
    .init({
      detection,
      resources,
      fallbackLng: DEFAULT_LANG,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    })
    .then(() => {
      console.log("i18n init successfully.");
    });
  return i18next;
};

export { i18next, DEFAULT_LANG, initI18n };
