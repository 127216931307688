/*
 * @Description:
 * @Author: linchen13
 * @Date: 2024-04-09 15:44:11
 * @LastEditTime: 2024-04-25 16:54:36
 * @LastEditors: wyhuo2
 */
import { initI18n } from '@/ect-front-common/src/i18n';

const resources = {
  zh: {
    download: {
      h1: '智能化在线考试系统',
      h2: '来华留学本科入学学业水平测试',
      btn: '立即下载'
    }
  },
  en: {
    download: {
      h1: 'Intelligent IBT System',
      h2: 'China Academic Proficiency Test for International Undergraduate Applicants',
      btn: 'Download'
    }
  }
};

const i18nInstance = initI18n([], resources);
export default i18nInstance;
